.menu_card {
    border-radius: 5px;
    border: 1px solid #f7f8f9 !important;
    padding: 5px;
    cursor: pointer;
}

.card {
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    -moz-box-shadow: -11px -3px 23px -7px rgba(0, 0, 0, 0.42);
    box-shadow: -11px -3px 23px -7px rgba(0, 0, 0, 0.42);
}

.card_data_section {
    background: #FFFF;
    /* min-height: 250px; */
}

.title_portion {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 18px;
    font-weight: bold;
}

.toggle {
    display: block;
    transform: scale(.7);
    margin-top: -7px;
    margin-right: -25px;
    /* background: red; */
}

.time_container {
    display: flex;
    justify-content: space-between;
}

.time {
    display: flex;
    align-items: center;
}

.right_portion {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.delete,
.edit {
    display: flex;
    gap: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.icon_container {
    margin-top: -2.5px;
}

.time_toggle {
    margin-top: 5px !important;
    margin-right: -25px !important;
    /* background: red;
    display: flex;
    align-items: center; */
}

.day_container {
    display: flex;
    justify-content: space-between;
}

.day_card {
    background: #EEEEEE;
    color: #495584;
    font-weight: bold;
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day_active {
    background: #00B2FF !important;
    color: white !important;
}

.set_temp_container {
    display: flex;
    gap: 15px;
    align-items: center;
}

.temp_text {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.mode_container {
    display: flex;
    justify-content: space-between;
}

.mode_type_container {
    display: flex;
    gap: 10px;
}

.mode_text {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.mode_card {
    background: #EEEEEE;
    color: #495584;
    font-weight: bold;
    height: 35px;
    width: 45px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mode_card_active {
    background: #00B2FF !important;
    color: white !important;
}